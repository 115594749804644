import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QueryStatus, authAxios } from "../utils";
import {
  GetSupportingDocument,
  SupportingDocumentsVerificationInProgress,
  TransactionsForVerificationList,
  UpdateDatasFromASupportingDocument,
  UpdateTransactionCategoryPayloadType,
} from "./supportingDocumentsApi";
import { RootState } from "../redux/store";
import axios, { AxiosError } from "axios";

export interface SupportingDocumentsState {
  getTransactionsForVerificationStatus: QueryStatus;
  getSupportingDocumentAsyncStatus: QueryStatus;
  updateSupportingDocumentAsyncStatus: QueryStatus;
  updateTransactionCategoryAsyncStatus: QueryStatus;
  transactionsForVerificationList: TransactionsForVerificationList[];
  currentSupportingDocument: GetSupportingDocument | null;
  isSupportingDocumentPdf: boolean;
  errorMessageSupportingDocumentData: string | null;
}

const initialState: SupportingDocumentsState = {
  getTransactionsForVerificationStatus: "idle",
  getSupportingDocumentAsyncStatus: "idle",
  updateSupportingDocumentAsyncStatus: "idle",
  updateTransactionCategoryAsyncStatus: "idle",
  transactionsForVerificationList: [],
  currentSupportingDocument: null,
  isSupportingDocumentPdf: false,
  errorMessageSupportingDocumentData: "",
};

export const getTransactionsForVerificationAsync = createAsyncThunk(
  "getTransactionsForVerification/call",
  async () => {
    const axios = authAxios();
    const response = await axios.get<TransactionsForVerificationList[]>(
      `/transactions/for_verification`,
    );
    return response.data.sort(
      (a, b) =>
        new Date(a.executionDate).getTime() -
        new Date(b.executionDate).getTime(),
    );
  },
);

export const supportingDocumentsVerificationInProgressAsync = createAsyncThunk(
  "supportingDocumentsVerificationInProgress/call",
  async (payload: SupportingDocumentsVerificationInProgress) => {
    const axios = authAxios();
    await axios.post(`supporting_documents/verification_in_progress`, payload);
  },
);

export const getSupportingDocumentAsync = createAsyncThunk(
  "getSupportingDocument/call",
  async (uuid: string, thunkAPI) => {
    const axiosRoute = authAxios();
    const response = await axiosRoute.get<GetSupportingDocument>(
      `transaction/${uuid}/get_supporting_document_data`,
    );
    const supportingDocument = response.data;
    let url = supportingDocument.presignedUrl;
    if (supportingDocument.presignedUrl && supportingDocument.fileName) {
      const fileNameSplit = supportingDocument.fileName.split(".");
      const extension = fileNameSplit[fileNameSplit.length - 1];
      if (extension === "pdf") {
        const response = await axios({
          url: supportingDocument.presignedUrl,
          method: "GET",
          responseType: "arraybuffer",
        });
        url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" }),
        );
        thunkAPI.dispatch(
          supportingDocumentsSlice.actions.setIsSupportingDocumentPdf(),
        );
      } else
        thunkAPI.dispatch(
          supportingDocumentsSlice.actions.resetSupportingDocumentPdf(),
        );
    }
    return { ...response.data, presignedUrl: url };
  },
);

export const updateSupportingDocumentDataAsync = createAsyncThunk(
  "sendSupportingDocumentData/call",
  async (payload: UpdateDatasFromASupportingDocument, thunkAPI) => {
    const axios = authAxios();
    try {
      await axios.post(
        "transaction/update_datas_from_a_supporting_document",
        payload,
      );
      thunkAPI.dispatch(
        supportingDocumentsSlice.actions.resetSupportingDocumentDataErrorMessage(),
      );
    } catch (e) {
      if (
        e instanceof AxiosError &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        e.response?.data?.message === "Supporting document already verified"
      ) {
        thunkAPI.dispatch(
          supportingDocumentsSlice.actions.setSupportingDocumentDataErrorMessage(),
        );
        void thunkAPI.dispatch(getTransactionsForVerificationAsync());
      }
      throw e;
    }
  },
);

export const updateTransactionCategory = createAsyncThunk(
  "updateTransactionCategory/call",
  async (payload: UpdateTransactionCategoryPayloadType) => {
    const axios = authAxios();
    await axios.post("transaction/update_category", payload);
  },
);

export const supportingDocumentsSlice = createSlice({
  name: "supportingDocuments",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetUpdateTransactionCategoryAsyncStatus(state) {
      state.updateTransactionCategoryAsyncStatus = "idle";
    },
    resetSupportingDocumentPdf(state) {
      state.isSupportingDocumentPdf = false;
    },
    setIsSupportingDocumentPdf(state) {
      state.isSupportingDocumentPdf = true;
    },
    setSupportingDocumentDataErrorMessage(state) {
      state.errorMessageSupportingDocumentData =
        "Supporting document already verified";
    },
    resetSupportingDocumentDataErrorMessage(state) {
      state.errorMessageSupportingDocumentData = null;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionsForVerificationAsync.pending, (state) => {
        state.getTransactionsForVerificationStatus = "processing";
      })
      .addCase(
        getTransactionsForVerificationAsync.fulfilled,
        (state, action) => {
          state.getTransactionsForVerificationStatus = "success";
          state.transactionsForVerificationList = action.payload;
        },
      )
      .addCase(getTransactionsForVerificationAsync.rejected, (state) => {
        state.getTransactionsForVerificationStatus = "failed";
      })
      .addCase(getSupportingDocumentAsync.pending, (state) => {
        state.getSupportingDocumentAsyncStatus = "processing";
      })
      .addCase(getSupportingDocumentAsync.fulfilled, (state, action) => {
        state.getSupportingDocumentAsyncStatus = "success";
        state.currentSupportingDocument = action.payload;
      })
      .addCase(getSupportingDocumentAsync.rejected, (state) => {
        state.getSupportingDocumentAsyncStatus = "failed";
      })
      .addCase(updateSupportingDocumentDataAsync.pending, (state) => {
        state.updateSupportingDocumentAsyncStatus = "processing";
      })
      .addCase(updateSupportingDocumentDataAsync.rejected, (state) => {
        state.updateSupportingDocumentAsyncStatus = "failed";
      })
      .addCase(updateSupportingDocumentDataAsync.fulfilled, (state) => {
        state.updateSupportingDocumentAsyncStatus = "success";
        state.getTransactionsForVerificationStatus = "idle";
      })
      .addCase(updateTransactionCategory.pending, (state) => {
        state.updateTransactionCategoryAsyncStatus = "processing";
      })
      .addCase(updateTransactionCategory.rejected, (state) => {
        state.updateTransactionCategoryAsyncStatus = "failed";
      })
      .addCase(updateTransactionCategory.fulfilled, (state) => {
        state.updateTransactionCategoryAsyncStatus = "success";
      });
  },
});

export const selectTransactionsForVerificationList = (state: RootState) =>
  state.supportingDocuments.transactionsForVerificationList;
export const selectTransactionsForVerificationStatus = (state: RootState) =>
  state.supportingDocuments.getTransactionsForVerificationStatus;
export const selectUpdateTransactionsCategoryStatus = (state: RootState) =>
  state.supportingDocuments.updateTransactionCategoryAsyncStatus;
export const selectGetSupportingDocument = (state: RootState) =>
  state.supportingDocuments.currentSupportingDocument;
export const selectIsSupportingDocumentPdf = (state: RootState) =>
  state.supportingDocuments.isSupportingDocumentPdf;
export const selectSupportingDocumentDataMessageError = (state: RootState) =>
  state.supportingDocuments.errorMessageSupportingDocumentData;
export const selectUpdateSupportingDocumentStatus = (state: RootState) =>
  state.supportingDocuments.updateSupportingDocumentAsyncStatus;

export default supportingDocumentsSlice.reducer;

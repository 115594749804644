import {
  triggerToast,
  dateFormatterDayMonthYearHourMinute,
  ButtonDS,
  DownloadOutlined,
  TableDS,
  Spacer,
  SelectDS,
  TextCapitalized,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { StatementsListDisplayed } from "./statementApi";
import { unreachable } from "../../utils";
import {
  selectStatementExportLink,
  selectStatementExportStatus,
  selectStatementForPeriodCreationStatus,
  selectStatementsList,
  selectStatementsListStatus,
  statementDownloadAsync,
  statementExportAsync,
  statementsListAsync,
} from "./statementSlice";
import { useEffect, useMemo, useState } from "react";
import {
  companiesListAsync,
  selectCompaniesList,
  selectCompaniesListStatus,
} from "./../companiesSlice";
import {
  selectZipFoldersExportLink,
  selectZipFoldersExportStatus,
  zipFolderDownloadAsync,
  zipFolderExportAsync,
} from "./../zipFolderSlice";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

export const StatementTab = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const companiesListStatus = useAppSelector(selectCompaniesListStatus);
  const companiesList = useAppSelector(selectCompaniesList);
  const statementsList = useAppSelector(selectStatementsList);
  const statementsListStatus = useAppSelector(selectStatementsListStatus);
  const statementCreationStatus = useAppSelector(
    selectStatementForPeriodCreationStatus,
  );
  const statementsExportStatus = useAppSelector(selectStatementExportStatus);
  const statementExportLink = useAppSelector(selectStatementExportLink);
  const zipFoldersExportStatus = useAppSelector(selectZipFoldersExportStatus);
  const zipFoldersExportLink = useAppSelector(selectZipFoldersExportLink);

  const [companySelected, setCompanySelected] = useState<string>("");
  const [listDisplayed, setListDisplayed] = useState<StatementsListDisplayed[]>(
    [],
  );

  const optionCompany = useMemo(
    () =>
      companiesList.map((company) => {
        return {
          label: company.name,
          value: company.uuid,
        };
      }),
    [companiesList],
  );

  const valueOptionCompany = useMemo(
    () => optionCompany.find((option) => option.value === companySelected),
    [companySelected, optionCompany],
  );

  if (companiesListStatus === "idle") {
    void dispatch(companiesListAsync());
  }

  if (statementsListStatus === "idle") {
    void dispatch(statementsListAsync());
  }
  useEffect(() => {
    if (statementCreationStatus === "success") {
      void dispatch(statementsListAsync());
    }
  }, [dispatch, statementCreationStatus]);

  useEffect(() => {
    if (companySelected)
      navigate(`/home/statements/${params.tab}/${companySelected}`);
  }, [companySelected, navigate, params.tab]);

  useEffect(() => {
    if (params.id) {
      if (companySelected !== params.id) setCompanySelected(params.id);

      setListDisplayed(
        statementsList.filter((statement) => statement.company === params.id),
      );
    }
  }, [companySelected, params.id, statementsList]);

  useEffect(() => {
    if (statementsExportStatus === "success") {
      triggerToast(t("statement.upload.success") || "", "valid");
    } else if (statementsExportStatus === "failed") {
      triggerToast(t("statement.upload.failure") || "", "error");
    }
  }, [statementsExportStatus, t]);

  useEffect(() => {
    if (statementExportLink.presignedUrl && statementExportLink.fileName) {
      void dispatch(statementDownloadAsync(statementExportLink));
    }
  }, [statementExportLink, dispatch]);

  useEffect(() => {
    if (zipFoldersExportLink.presignedUrl && zipFoldersExportLink.fileName) {
      void dispatch(zipFolderDownloadAsync(zipFoldersExportLink));
    }
  }, [zipFoldersExportLink, dispatch]);

  useEffect(() => {
    if (zipFoldersExportStatus === "success") {
      triggerToast(t("zipFolder.upload.success") || "", "valid");
    } else if (zipFoldersExportStatus === "failed") {
      triggerToast(t("zipFolder.upload.failure") || "", "error");
    }
  }, [zipFoldersExportStatus, t]);

  if (params.tab !== "statements") {
    return;
  }

  const headersStatementsList = {
    companyName: {
      text: t("statement.page.column.company"),
    },
    date: {
      text: t("statement.page.column.date"),
    },
    fileName: {
      text: t("statement.page.column.fileName"),
    },
    number: {
      text: t("statement.page.column.number"),
    },
    bucketFileName: {
      text: t("statement.page.column.download.xlsx"),
    },
    uuid: {
      text: t("statement.page.column.download.pdf"),
    },
    zipFolder: {
      text: t("statement.page.column.zipFolder"),
    },
  };

  const renderStatementsList =
    (row: StatementsListDisplayed) => (key: keyof StatementsListDisplayed) => {
      switch (key) {
        case "companyName":
        case "number":
        case "fileName":
          return row[key];
        case "bucketFileName":
          return (
            <ButtonDS
              sizeButton={"S"}
              format={"hug"}
              buttonType={"secondary"}
              singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
              onClick={() => {
                void dispatch(
                  statementExportAsync({
                    bucketFileName: row["bucketFileName"] ?? "",
                    fileName: row["fileName"],
                  }),
                );
              }}
            />
          );
        case "date":
          return dateFormatterDayMonthYearHourMinute(new Date(row[key]));
        case "uuid":
          return (
            <ButtonDS
              sizeButton={"S"}
              format={"hug"}
              buttonType={"secondary"}
              singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
              onClick={() => {
                void dispatch(
                  statementExportAsync({
                    bucketFileName: `${row["uuid"]}.pdf`,
                    fileName: `${row["fileName"].split(".xlsx")[0]}.pdf`,
                  }),
                );
              }}
            />
          );
        case "zipFolder":
          return (
            row["zipFolder"] && (
              <ButtonDS
                sizeButton={"S"}
                format={"hug"}
                buttonType={"secondary"}
                singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
                onClick={() => {
                  void dispatch(
                    zipFolderExportAsync({
                      uuid: `${row["zipFolder"]}`,
                      fileName: `${row["fileName"].split(".xlsx")[0]}.zip`,
                    }),
                  );
                }}
              />
            )
          );
      }
      unreachable(key);
    };

  return (
    <StyledContainer>
      <StyledHeader>
        <TextCapitalized>{t("select.company")}</TextCapitalized>
        <SelectDS
          label={""}
          value={valueOptionCompany}
          options={optionCompany}
          onChange={(selectedOption) =>
            setCompanySelected(selectedOption ? selectedOption.value : "")
          }
          allWidth
        />
      </StyledHeader>
      <TableDS<keyof StatementsListDisplayed, StatementsListDisplayed>
        data={listDisplayed}
        headers={headersStatementsList}
        render={renderStatementsList}
      />
      <Spacer y={3} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
`;

import {
  triggerToast,
  dateFormatterDayMonthYearHourMinute,
  ButtonDS,
  DownloadOutlined,
  TableDS,
  Spacer,
  SelectDS,
  TextCapitalized,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { BankStatementsListDisplayed } from "./statementApi";
import { unreachable } from "../../utils";
import {
  bankStatementDownloadAsync,
  bankStatementExportAsync,
  bankStatementsListAsync,
  selectBankStatementExportLink,
  selectBankStatementExportStatus,
  selectBankStatementsList,
  selectBankStatementsListStatus,
} from "./statementSlice";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  companiesListAsync,
  selectCompaniesList,
  selectCompaniesListStatus,
} from "../companiesSlice";
import styled from "styled-components";

export const BankStatementTab = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const companiesList = useAppSelector(selectCompaniesList);
  const companiesListStatus = useAppSelector(selectCompaniesListStatus);
  const bankStatementsList = useAppSelector(selectBankStatementsList);
  const bankStatementsListStatus = useAppSelector(
    selectBankStatementsListStatus,
  );

  const bankStatementsExportStatus = useAppSelector(
    selectBankStatementExportStatus,
  );
  const bankStatementExportLink = useAppSelector(selectBankStatementExportLink);
  const [companySelected, setCompanySelected] = useState<string>("");
  const [listDisplayed, setListDisplayed] = useState<
    BankStatementsListDisplayed[]
  >([]);

  if (bankStatementsListStatus === "idle") {
    void dispatch(bankStatementsListAsync());
  }

  useEffect(() => {
    if (companiesListStatus === "idle") {
      void dispatch(companiesListAsync());
    }
  }, [companiesListStatus, dispatch]);

  const optionCompany = useMemo(
    () =>
      companiesList.map((company) => {
        return {
          label: company.name,
          value: company.uuid,
        };
      }),
    [companiesList],
  );

  const valueOptionCompany = useMemo(
    () => optionCompany.find((option) => option.value === companySelected),
    [companySelected, optionCompany],
  );

  useEffect(() => {
    if (bankStatementsExportStatus === "success") {
      triggerToast(t("bankStatement.upload.success") || "", "valid");
    } else if (bankStatementsExportStatus === "failed") {
      triggerToast(t("bankStatement.upload.failure") || "", "error");
    }
  }, [bankStatementsExportStatus, t]);

  useEffect(() => {
    if (
      bankStatementExportLink.presignedUrl &&
      bankStatementExportLink.fileName
    ) {
      void dispatch(bankStatementDownloadAsync(bankStatementExportLink));
    }
  }, [bankStatementExportLink, dispatch]);

  useEffect(() => {
    if (companySelected) {
      navigate(`/home/statements/${params.tab}/${companySelected}`);
      setListDisplayed(
        bankStatementsList.filter(
          (bankStatement) => bankStatement.company === companySelected,
        ),
      );
    }
  }, [bankStatementsList, companySelected, navigate, params.tab]);

  useEffect(() => {
    if (params.id && companySelected !== params.id) {
      setCompanySelected(params.id);
    }
  }, [companySelected, params.id]);

  if (params.tab !== "bankStatements") {
    return;
  }

  const headersBankStatementsList = {
    companyName: {
      text: t("bankStatement.page.column.company"),
    },
    date: {
      text: t("bankStatement.page.column.date"),
    },
    closingBalance: {
      text: t("bankStatement.page.column.closingBalance"),
    },

    fileName: {
      text: t("bankStatement.page.column.fileName"),
    },
    bucketFileName: {
      text: t("bankStatement.page.column.download.pdf"),
    },
  };

  const renderBankStatementsList =
    (row: BankStatementsListDisplayed) =>
    (key: keyof BankStatementsListDisplayed) => {
      switch (key) {
        case "companyName":
        case "fileName":
          return row[key];
        case "closingBalance":
          return `${row[key] === null ? "-" : (row[key] / 100).toString() + " EUR"}`;
        case "bucketFileName":
          return (
            row[key] && (
              <ButtonDS
                sizeButton={"S"}
                format={"hug"}
                buttonType={"secondary"}
                singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
                onClick={() => {
                  void dispatch(
                    bankStatementExportAsync({
                      bucketFileName: row["bucketFileName"] ?? "",
                      fileName: row["fileName"],
                    }),
                  );
                }}
              />
            )
          );
        case "date":
          return dateFormatterDayMonthYearHourMinute(new Date(row[key]));
      }
      unreachable(key);
    };

  return (
    <StyledContainer>
      <StyledHeader>
        <TextCapitalized>{t("select.company")}</TextCapitalized>
        <SelectDS
          label={""}
          value={valueOptionCompany}
          options={optionCompany}
          onChange={(selectedOption) =>
            setCompanySelected(selectedOption ? selectedOption.value : "")
          }
          allWidth
        />
      </StyledHeader>
      <TableDS<keyof BankStatementsListDisplayed, BankStatementsListDisplayed>
        data={listDisplayed}
        headers={headersBankStatementsList}
        render={renderBankStatementsList}
      />
      <Spacer y={3} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
`;

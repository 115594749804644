import {
  ButtonDS,
  CheckOutlined,
  InputDS,
  LaunchPage,
  MaterialUiColumnsProps,
  MaterialUiTable,
  Spacer,
  triggerToast,
  usePersistMuiTableState,
} from "@qivia/ui";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import styled from "styled-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  companyFollowUpAsync,
  companyFollowUpSlice,
  selectCompanyFollowUp,
  selectCompanyFollowUpStatus,
  selectCreateCompanyAcronymStatus,
  createCompanyAcronymAsync,
} from "./followUpSlice";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { CompanyFollowUpDisplayed } from "./followUpApi";
import { useTranslation } from "react-i18next";

type AcronymInputProps = {
  companyUuid: string;
  isAcronymValid: (input: string) => boolean;
};

const AcronymInput = (props: AcronymInputProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [newAcronym, setNewAcronym] = useState<string>();

  const clickToSubmit = useCallback(() => {
    if (!newAcronym) return;
    props.isAcronymValid(newAcronym) &&
      void dispatch(
        createCompanyAcronymAsync({
          companyUuid: props.companyUuid,
          acronym: newAcronym,
        }),
      );
  }, [dispatch, newAcronym, props]);

  return (
    <StyledRow>
      <InputDS
        error={
          newAcronym && !props.isAcronymValid(newAcronym)
            ? t("companyFollowUp.acronym.input.error")
            : undefined
        }
        placeholder={t("companyFollowUp.acronym.input.placeholder")}
        value={newAcronym}
        onKeyDown={(e) => e.key === "Enter" && clickToSubmit()}
        onChange={(e) => setNewAcronym(e.target.value)}
        withoutSpacerBottom
      />
      <ButtonDS
        singleIcon={{ icon: <CheckOutlined />, size: "S" }}
        format="fill"
        buttonType="primary"
        onClick={clickToSubmit}
      />
    </StyledRow>
  );
};

export const CompanyFollowUpTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const companyFollowUpList = useAppSelector(selectCompanyFollowUp);
  const companyFollowUpListStatus = useAppSelector(selectCompanyFollowUpStatus);
  const createCompanyAcronymStatus = useAppSelector(
    selectCreateCompanyAcronymStatus,
  );
  const [listDisplayed, setListDisplayed] = useState<
    CompanyFollowUpDisplayed[] | null
  >(null);
  const pageName = "companyFollowUp";
  const apiRef = useGridApiRef();
  const key = "customers-grid";

  const aggregation = {
    model: {
      amount: "avg",
      merchantName: "size",
      category: "size",
      driverName: "size",
    },
  };

  const companyListWithId = useMemo(
    () => companyFollowUpList.map((cd) => ({ ...cd, id: cd.companyUuid })),
    [companyFollowUpList],
  );

  const dataGridState = usePersistMuiTableState(
    apiRef,
    key,
    pageName,
    aggregation,
  );

  const searchBarProps = {
    values: companyListWithId,
    setFilterValues: setListDisplayed,
    keysToIgnore: ["uuid" as const],
  };

  const isAcronymValid = useCallback(
    (input: string) =>
      companyFollowUpList.reduce(
        (acc, curr) => acc && curr.acronym !== input,
        true,
      ),
    [companyFollowUpList],
  );

  useEffect(() => {
    if (createCompanyAcronymStatus === "success") {
      triggerToast(t("companyFollowUp.acronym.create.success") || "", "valid");
      dispatch(companyFollowUpSlice.actions.resetUpdateStatus());
    } else if (createCompanyAcronymStatus === "failed") {
      triggerToast(t("companyFollowUp.acronym.create.failure") || "", "error");
    }
  }, [createCompanyAcronymStatus, t, dispatch]);

  useEffect(() => {
    if (companyFollowUpListStatus === "idle") {
      void dispatch(companyFollowUpAsync());
    }
  }, [companyFollowUpListStatus, dispatch]);

  const columnsDisplayed: {
    [index in keyof CompanyFollowUpDisplayed]: MaterialUiColumnsProps;
  } = useMemo(() => {
    return {
      name: {
        type: "string",
        width: 200,
      },
      balance: {
        type: "number",
        width: 200,
        valueFormatter: (value: number) => {
          return value ? <>{Math.round(value / 100)} €</> : <>N/A</>;
        },
      },
      totalCardsCount: {
        type: "number",
        width: 200,
        valueFormatter: (value: number) => {
          return value ? <>{value}</> : <>N/A</>;
        },
      },
      activeCardsCount: {
        type: "number",
        width: 200,
        valueFormatter: (value: number) => {
          return value ? <>{value}</> : <>N/A</>;
        },
      },
      inOppositionCardsCount: {
        type: "number",
        width: 200,
        valueFormatter: (value: number) => {
          return value ? <>{value}</> : <>N/A</>;
        },
      },
      acronym: {
        type: "string",
        width: 300,
        renderCell: (props) => {
          if (props.value) return <>{props.value}</>;
          return (
            <AcronymInput
              isAcronymValid={isAcronymValid}
              companyUuid={props.row["companyUuid"] as string}
            />
          );
        },
      },
    };
  }, [isAcronymValid]);

  useEffect(() => {
    if (companyListWithId && companyFollowUpListStatus === "success") {
      setListDisplayed(companyListWithId);
    }
  }, [companyListWithId, companyFollowUpListStatus]);

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  if (params.tab !== "companyFollowUp") {
    return;
  }

  return (
    <StyledContainer>
      <Spacer y={2} />
      <MaterialUiTable<keyof CompanyFollowUpDisplayed, CompanyFollowUpDisplayed>
        rows={listDisplayed}
        columns={columnsDisplayed}
        pageName={pageName}
        dataGridState={dataGridState}
        searchBar={searchBarProps}
        apiRef={apiRef}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledRow = styled.div`
  display: flex;
`;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QueryStatus, authAxios } from "../../../src/utils";
import { RootState } from "../../redux/store";
import {
  CreateInvoice,
  ExportInvoice,
  InvoicesList,
  InvoicesListDisplayed,
} from "./invoiceApi";
import axios from "axios";

export interface InvoiceState {
  createInvoiceStatus: QueryStatus;
  invoicesList: InvoicesListDisplayed[];
  invoicesListStatus: QueryStatus;
  invoiceExportStatus: QueryStatus;
  invoiceDownloadStatus: QueryStatus;
  invoiceExportLink: ExportInvoice;
}

const initialState: InvoiceState = {
  createInvoiceStatus: "idle",
  invoicesList: [],
  invoicesListStatus: "idle",
  invoiceExportStatus: "idle",
  invoiceDownloadStatus: "idle",
  invoiceExportLink: {
    presignedUrl: null,
    fileName: null,
  },
};

export const createInvoiceAsync = createAsyncThunk(
  "createInvoiceAsync/call",
  async (payload: CreateInvoice) => {
    const axios = authAxios();
    await axios.post(`invoice_creation`, payload);
  },
);

export const getInvoicesListAsync = createAsyncThunk(
  "getInvoicesListAsync/call",
  async (payload: { company: string }) => {
    const axios = authAxios();
    const response = await axios.get<InvoicesList[]>(
      `/company/${payload.company}/invoices`,
    );
    const formattedResult = response.data.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
    );
    return formattedResult.map((row) => {
      return {
        ...row,
        activeCards: row.activeCards?.length ?? 0,
        activeTransactions: row.activeTransactions?.length ?? 0,
        activeBadges: row.activeBadges?.length ?? 0,
        badgeTransactions: row.badgeTransactions?.length ?? 0,
        analyticVehicles: row.analyticVehicles?.length ?? 0,
        advancedAnalyticVehicles: row.advancedAnalyticVehicles?.length ?? 0,
        fileName: row.name,
      };
    });
  },
);

export const invoiceExportAsync = createAsyncThunk(
  "invoiceExport/call",
  async (payload: { bucketFileName: string; fileName: string }) => {
    const axios = authAxios();
    const response = await axios.get<{ presignedUrl: string }>(
      `/invoice/${payload.bucketFileName}/export`,
    );
    return {
      presignedUrl: response.data.presignedUrl,
      fileName: payload.fileName,
    };
  },
);

export const invoiceDownloadAsync = createAsyncThunk(
  "invoiceDownload/call",
  async (invoiceExportLink: ExportInvoice) => {
    if (invoiceExportLink.presignedUrl && invoiceExportLink.fileName) {
      const response = await axios({
        url: invoiceExportLink.presignedUrl,
        method: "GET",
        responseType: "blob",
      });

      const a = document.createElement("a");
      a.download = invoiceExportLink.fileName || "yourInvoice.pdf";
      const url = window.URL.createObjectURL(new Blob([response.data]));
      a.href = url;
      a.click();
    }
  },
);

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(createInvoiceAsync.pending, (state) => {
        state.createInvoiceStatus = "processing";
      })
      .addCase(createInvoiceAsync.fulfilled, (state) => {
        state.createInvoiceStatus = "success";
        state.invoicesListStatus = "idle";
      })
      .addCase(createInvoiceAsync.rejected, (state) => {
        state.createInvoiceStatus = "failed";
      })
      .addCase(getInvoicesListAsync.pending, (state) => {
        state.invoicesListStatus = "processing";
      })
      .addCase(getInvoicesListAsync.fulfilled, (state, action) => {
        state.invoicesList = action.payload;
        state.invoicesListStatus = "success";
      })
      .addCase(getInvoicesListAsync.rejected, (state) => {
        state.invoicesListStatus = "failed";
      })
      .addCase(invoiceExportAsync.pending, (state) => {
        state.invoiceExportStatus = "processing";
      })
      .addCase(invoiceExportAsync.fulfilled, (state, action) => {
        state.invoiceExportLink = action.payload;
        state.invoiceExportStatus = "success";
      })
      .addCase(invoiceExportAsync.rejected, (state) => {
        state.invoiceExportStatus = "failed";
      })
      .addCase(invoiceDownloadAsync.pending, (state) => {
        state.invoiceDownloadStatus = "processing";
      })
      .addCase(invoiceDownloadAsync.fulfilled, (state) => {
        state.invoiceExportLink.presignedUrl = null;
        state.invoiceExportLink.fileName = null;
        state.invoiceDownloadStatus = "success";
        state.invoiceExportStatus = "idle";
      })
      .addCase(invoiceDownloadAsync.rejected, (state) => {
        state.invoiceDownloadStatus = "failed";
      });
  },
});

export const selectCreateInvoiceStatus = (state: RootState) =>
  state.invoice.createInvoiceStatus;
export const selectInvoicesList = (state: RootState) =>
  state.invoice.invoicesList;
export const selectInvoicesListStatus = (state: RootState) =>
  state.invoice.invoicesListStatus;
export const selectInvoicetExportStatus = (state: RootState) =>
  state.invoice.invoiceExportStatus;
export const selectInvoiceExportLink = (state: RootState) =>
  state.invoice.invoiceExportLink;

export default invoiceSlice.reducer;

import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import homeReducer from "../qg_front/homeSlice";
import qrCodeReducer from "../qg_front/qrCodeSlice";
import statementReducer from "../qg_front/statements/statementSlice";
import zipFolderReducer from "../qg_front/zipFolderSlice";
import termsAndPolicyReducer from "../qg_front/termsAndPolicySlice";
import pricingReducer from "../qg_front/billings/pricingSlice";
import invoiceReducer from "../qg_front/billings/invoiceSlice";
import supportingDocumentsReducer from "../qg_front/supportingDocumentsSlice";
import companiesReducer from "../qg_front/companiesSlice";
import depositReducer from "../qg_front/billings/depositSlice";
import transactionReducer from "../qg_front/companiesData/transactionsSlice";
import accountDataReducer from "../qg_front/companiesData/accountDataSlice";
import authorizationsReducer from "../qg_front/companiesData/rejectedAuthorizationsSlice";
import driversReducer from "../qg_front/companiesDrivers/driversSlice";
import tankCapacityReducer from "../qg_front/tankCapacities/tankCapacitiesSlice";
import asfInvoiceReducer from "../qg_front/billings/asfInvoiceSlice";
import electricReducer from "../qg_front/electric/electricSlice";
import authorizationReducer from "../qg_front/authorizationSlice";
import companyFollowUpReducer from "../qg_front/companiesData/followUpSlice";

export const store = configureStore({
  reducer: {
    home: homeReducer,
    qrCode: qrCodeReducer,
    statement: statementReducer,
    zipFolder: zipFolderReducer,
    termsAndPolicy: termsAndPolicyReducer,
    pricing: pricingReducer,
    invoice: invoiceReducer,
    supportingDocuments: supportingDocumentsReducer,
    companies: companiesReducer,
    deposit: depositReducer,
    transactions: transactionReducer,
    accountData: accountDataReducer,
    authorizations: authorizationsReducer,
    drivers: driversReducer,
    tankCapacities: tankCapacityReducer,
    asfInvoice: asfInvoiceReducer,
    electric: electricReducer,
    authorization: authorizationReducer,
    companyFollowUp: companyFollowUpReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

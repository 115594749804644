import {
  ButtonDS,
  EditOutlined,
  LaunchPage,
  MaterialUiColumnsProps,
  MaterialUiTable,
  SelectDS,
  Spacer,
  TextCapitalized,
  dateFormatterDayMonthYearLong2Digits,
  triggerToast,
  usePersistMuiTableState,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import styled from "styled-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  companiesSlice,
  companiesListAsync,
  selectCompaniesList,
  selectCompaniesListStatus,
} from "../companiesSlice";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import {
  rejectedAuthorizationsSlice,
  rejectedAuthorizationsAsync,
  selectRejectedAuthorizationsList,
} from "../companiesData/rejectedAuthorizationsSlice";
import { RejectedAuthorizationsList } from "../companiesData/rejectedAuthorizationsApi";
import { AuthorizationsWithUnkwnownCategoryListDisplayed } from "./merchantFixApi";
import { formatDate, getDate } from "../../lib/materialUiFormats";
import { CategoriesMerchantCorrectionModal } from "../modals/categoriesMerchantCorrectionModal";
import {
  authorizationSlice,
  selectCreateMerchantCodeStatus,
} from "../authorizationSlice";

export const MerchantCodeTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const companiesList = useAppSelector(selectCompaniesList);
  const companiesListStatus = useAppSelector(selectCompaniesListStatus);
  const authorizationsRejected = useAppSelector(
    selectRejectedAuthorizationsList,
  );
  const createMerchantCodeStatus = useAppSelector(
    selectCreateMerchantCodeStatus,
  );
  const [companySelected, setCompanySelected] = useState<string>("");
  const [listDisplayed, setListDisplayed] = useState<
    AuthorizationsWithUnkwnownCategoryListDisplayed[] | null
  >(null);
  const [currentAuthorizationDetailsUuid, setCurrentAuthorizationDetailsUuid] =
    useState<string | null>(null);

  const pageName = "merchantCode";
  const apiRef = useGridApiRef();
  const key = "customers-grid";

  const aggregation = {
    model: {
      amount: "avg",
      merchantName: "size",
      category: "size",
      driverName: "size",
    },
  };

  const dataGridState = usePersistMuiTableState(
    apiRef,
    key,
    pageName,
    aggregation,
  );

  const findKeysToTranslate = useCallback(
    (key: keyof RejectedAuthorizationsList, value: string | number) => {
      switch (key) {
        case "executionDate":
          return dateFormatterDayMonthYearLong2Digits(new Date(value));
        default:
          return value;
      }
    },
    [],
  );

  const keysToTranslate = useMemo(
    () =>
      [
        "executionDate",
      ] as (keyof AuthorizationsWithUnkwnownCategoryListDisplayed)[],
    [],
  );

  const optionCompany = useMemo(
    () =>
      companiesList.map((company) => {
        return {
          label: company.name,
          value: company.uuid,
        };
      }),
    [companiesList],
  );

  const valueOptionCompany = useMemo(
    () => optionCompany.find((option) => option.value === companySelected),
    [companySelected, optionCompany],
  );

  const allAuthorizationsWithUnkwnownCategoryListDisplayed: RejectedAuthorizationsList[] =
    useMemo(() => {
      if (!authorizationsRejected) return [];

      const authorizationsFiltered = authorizationsRejected.filter(
        (a) =>
          a.category === null &&
          a.categoryAlreadyCorrected === false &&
          a.reasonsRefused.includes("UNAUTHORIZE_MERCHANT"),
      );

      const rowsWithId = authorizationsFiltered.map((av) => {
        let newDict: RejectedAuthorizationsList & {
          id: string;
        } = {
          ...av,
          id: av.uuid,
        };
        Object.entries(av).forEach(([key, value]) => {
          if (
            keysToTranslate.includes(
              key as keyof AuthorizationsWithUnkwnownCategoryListDisplayed,
            )
          ) {
            newDict = {
              ...newDict,
              [key]: value
                ? findKeysToTranslate(
                    key as keyof RejectedAuthorizationsList,
                    value as string,
                  )
                : ("" as string),
            };
          }
        });
        return newDict;
      });
      return rowsWithId;
    }, [authorizationsRejected, findKeysToTranslate, keysToTranslate]);

  const currentAuthorizationDetails: RejectedAuthorizationsList | null =
    useMemo(
      () =>
        allAuthorizationsWithUnkwnownCategoryListDisplayed.find(
          (t) => "id" in t && t.id === currentAuthorizationDetailsUuid,
        ) ?? null,
      [
        allAuthorizationsWithUnkwnownCategoryListDisplayed,
        currentAuthorizationDetailsUuid,
      ],
    );

  const searchBarProps = {
    values: allAuthorizationsWithUnkwnownCategoryListDisplayed,
    setFilterValues: setListDisplayed,
    keysToIgnore: [
      "uuid" as const,
      "cardLast4Digits" as const,
      "reasonsRefused" as const,
      "driver" as const,
      "driverFirstName" as const,
      "driverLastName" as const,
      "ruleUuid" as const,
      "driver" as const,
      "vehicleUuid" as const,
      "cardUuid" as const,
      "vehicleReference" as const,
      "merchantGroup" as const,
    ],
  };

  useEffect(() => {
    if (companiesListStatus === "idle") {
      void dispatch(companiesListAsync());
    }
  }, [companiesListStatus, dispatch]);

  const resetValue = useCallback(() => {
    setCurrentAuthorizationDetailsUuid(null);
    dispatch(companiesSlice.actions.resetCompaniesListStatus());
    dispatch(authorizationSlice.actions.resetCreateMerchantCodeStatus());
    dispatch(
      rejectedAuthorizationsSlice.actions.resetAuthorizationsListStatus(),
    );
  }, [dispatch]);

  useEffect(() => {
    if (createMerchantCodeStatus === "success") {
      triggerToast(t("merchantCode.creation.success") || "", "valid");
      resetValue();
    } else if (createMerchantCodeStatus === "failed") {
      triggerToast(t("merchantCode.creation.failure") || "", "error");
    }
  }, [createMerchantCodeStatus, resetValue, t]);

  useEffect(() => {
    if (companySelected) {
      void dispatch(
        rejectedAuthorizationsAsync({
          companyUuid: companySelected,
        }),
      );
    }
  }, [companySelected, dispatch, createMerchantCodeStatus]);

  useEffect(() => {
    if (
      allAuthorizationsWithUnkwnownCategoryListDisplayed &&
      companiesList &&
      companiesListStatus === "success"
    ) {
      setListDisplayed(allAuthorizationsWithUnkwnownCategoryListDisplayed);
    }
  }, [
    allAuthorizationsWithUnkwnownCategoryListDisplayed,
    companiesList,
    companiesListStatus,
  ]);

  const columnsDisplayed: {
    [index in keyof AuthorizationsWithUnkwnownCategoryListDisplayed]: MaterialUiColumnsProps;
  } = useMemo(() => {
    return {
      merchantCodeCreation: {
        type: "string",
        width: 100,
        valueFormatter: (value, row) => {
          return (
            <StyledButton>
              <Spacer y={0.5} />
              <ButtonDS
                singleIcon={{
                  icon: <EditOutlined />,
                  size: "L",
                }}
                format={"fill"}
                buttonType={"secondary"}
                onClick={() => {
                  setCurrentAuthorizationDetailsUuid(row["id"] as string);
                }}
              />
              <Spacer y={0.5} />
            </StyledButton>
          );
        },
      },
      executionDate: {
        type: "date",
        width: 140,
        valueGetter: getDate,
        valueFormatter: formatDate,
      },
      driverName: {
        type: "string",
        width: 150,
        valueFormatter: (value: string) => {
          return value ? <StyledRow>{value}</StyledRow> : <>-</>;
        },
      },
      registrationNumber: {
        type: "string",
        width: 200,
        valueFormatter: (value: string) => {
          return value ? <StyledRow>{value}</StyledRow> : <>-</>;
        },
      },
      merchantCity: {
        type: "string",
        width: 200,
      },
      merchantCode: {
        type: "string",
        width: 150,
      },
      amount: {
        type: "number",
        width: 150,
        valueFormatter: (value: string) => {
          return value && <>{value} €</>;
        },
      },
      isAmountControllable: {
        type: "boolean",
        width: 100,
      },
      merchantName: {
        type: "string",
        width: 200,
      },
      category: {
        type: "string",
        width: 100,
      },
      ruleName: {
        type: "string",
        width: 200,
      },
    };
  }, []);

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  if (params.tab !== "merchantCode") {
    return;
  }

  return (
    <StyledContainer>
      <Spacer y={1} />
      <StyledHeader>
        <TextCapitalized>{t("merchantCode.select.company")}</TextCapitalized>
        <SelectDS
          label={""}
          value={valueOptionCompany}
          options={optionCompany}
          onChange={(selectedOption) =>
            setCompanySelected(selectedOption ? selectedOption.value : "")
          }
          allWidth
        />
      </StyledHeader>
      <Spacer y={1} />
      <MaterialUiTable<
        keyof AuthorizationsWithUnkwnownCategoryListDisplayed,
        AuthorizationsWithUnkwnownCategoryListDisplayed
      >
        rows={listDisplayed}
        columns={columnsDisplayed}
        pageName={pageName}
        dataGridState={dataGridState}
        searchBar={searchBarProps}
        apiRef={apiRef}
      />
      <>
        <Spacer y={2} />
        <CategoriesMerchantCorrectionModal
          visible={!!currentAuthorizationDetails}
          onCloseModal={() => setCurrentAuthorizationDetailsUuid(null)}
          authorization={currentAuthorizationDetails}
        />
      </>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
  z-index: 10;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StyledButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

import {
  ButtonDS,
  DialogModalDS,
  SelectDS,
  Spacer,
  TextCapitalized,
} from "@qivia/ui";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { CategoryType } from "../supportingDocumentsApi";
import { useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { createMerchantCodeAsync } from "../authorizationSlice";
import { selectAdminUser } from "../homeSlice";
import { RejectedAuthorizationsList } from "../companiesData/rejectedAuthorizationsApi";

const categoriesMerchantCorrection: CategoryType[] = [
  "FUEL_AUTOMATON",
  "SERVICE_STATION",
  "WASH",
  "TOLL",
  "PARKING",
];

export const CategoriesMerchantCorrectionModal = (props: {
  visible: boolean;
  onCloseModal: () => void;
  authorization: RejectedAuthorizationsList | null;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { visible, onCloseModal, authorization } = props;
  const [category, setCategory] = useState<CategoryType | null>(null);
  const [displayError, setDisplayError] = useState<boolean>(false);

  const adminUser = useAppSelector(selectAdminUser);

  const optionsCategories = useMemo(
    () =>
      categoriesMerchantCorrection.map((c) => {
        return {
          value: c,
          label: t(`category.${c}`),
        };
      }),
    [t],
  );

  const valueCategory = useMemo(
    () => optionsCategories.find((option) => option.value === category),
    [category, optionsCategories],
  );

  const resetModal = useCallback(() => {
    setDisplayError(false);
    setCategory(null);
    onCloseModal();
  }, [onCloseModal]);

  const clickToSubmit = useCallback(() => {
    setDisplayError(true);
    if (!category || !authorization || !adminUser) return;
    void dispatch(
      createMerchantCodeAsync({
        authorizationUuid: authorization.uuid,
        category,
        updatedBy: adminUser.email,
      }),
    );
    resetModal();
  }, [adminUser, authorization, category, dispatch, resetModal]);

  if (!authorization) {
    return;
  }

  return (
    <DialogModalDS
      visible={visible}
      onClose={resetModal}
      title={t("merchantCode.modal.title")}
      width={40}
      bottomButton={
        <ButtonDS
          text={t(`merchantCode.modal.title.confirm`)}
          format={"fill"}
          buttonType={"primary"}
          disabled={!category}
          onClick={clickToSubmit}
        />
      }
    >
      <StyledColumn>
        <TextCapitalized>
          {t("merchantCode.modal.categoryCode")}
          {authorization.merchantCode}
        </TextCapitalized>
        <Spacer y={1} />
        {t("merchantCode.modal.amount")}
        {authorization.amount}
        <Spacer y={1} />
        {t("merchantCode.modal.isAmountControllable")}
        {authorization.isAmountControllable.toString()}
        <Spacer y={1} />
        <TextCapitalized>
          {t("merchantCode.modal.merchantName")}
          {authorization.merchantName}
        </TextCapitalized>
        <Spacer y={3} />
        <SelectDS
          label={t("merchantCode.modal.merchantGroups") || ""}
          options={optionsCategories}
          placeholder={""}
          onChange={(e) => setCategory(e ? e.value : null)}
          value={valueCategory}
          isMandatory
          allWidth
          error={
            displayError && !category
              ? t("merchantCode.modal.select.error")
              : undefined
          }
        />
      </StyledColumn>
    </DialogModalDS>
  );
};

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QueryStatus, authAxios } from "../../utils";
import { RootState } from "../../redux/store";
import {
  CompanyAccountDataDisplayed,
  CompanyCardsDataDisplayed,
  ExportBankDetails,
  GetCompanyAccountDataApi,
} from "./accountDataApi";
import axios from "axios";

export interface AccountDataState {
  companyAccountData: CompanyAccountDataDisplayed;
  companyCardsData: CompanyCardsDataDisplayed;
  companyAccountDataStatus: QueryStatus;
  bankDetailsExportStatus: QueryStatus;
  bankDetailsExportLink: ExportBankDetails;
  bankDetailsDownloadStatus: QueryStatus;
}

const initialState: AccountDataState = {
  companyAccountDataStatus: "idle",
  companyAccountData: {
    name: "",
    iban: "",
    bic: "",
    address: "",
    bankDetailsBucketFileName: null,
    providerId: "",
    managers: [],
  },
  companyCardsData: {
    activeCardsCount: null,
    totalCardsCount: null,
    inOppositionCardsCount: null,
  },
  bankDetailsExportStatus: "idle",
  bankDetailsExportLink: {
    presignedUrl: null,
    fileName: null,
  },
  bankDetailsDownloadStatus: "idle",
};

export const companyAccountDataAsync = createAsyncThunk(
  "companyAccountData/call",
  async (payload: { companyUuid: string }) => {
    const axios = authAxios();
    const response = await axios.get<GetCompanyAccountDataApi>(
      `company/${payload.companyUuid}/account_data`,
    );
    return response.data;
  },
);

export const bankDetailsExportAsync = createAsyncThunk(
  "bankDetailsExport/call",
  async (payload: { bucketFileName: string; fileName: string }) => {
    const axios = authAxios();
    const response = await axios.get<{ presignedUrl: string }>(
      `/company/bank_details/${payload.bucketFileName}/export`,
    );
    return {
      presignedUrl: response.data.presignedUrl,
      fileName: payload.fileName,
    };
  },
);

export const bankDetailsDownloadAsync = createAsyncThunk(
  "bankDetailsDownload/call",
  async (bankDetailsExportLink: ExportBankDetails) => {
    if (bankDetailsExportLink.presignedUrl && bankDetailsExportLink.fileName) {
      const response = await axios({
        url: bankDetailsExportLink.presignedUrl,
        method: "GET",
        responseType: "blob",
      });

      const a = document.createElement("a");
      a.download = bankDetailsExportLink.fileName || "rib.pdf";
      const url = window.URL.createObjectURL(new Blob([response.data]));
      a.href = url;
      a.click();
    }
  },
);

export const accountDataSlice = createSlice({
  name: "accountData",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(companyAccountDataAsync.pending, (state) => {
        state.companyAccountDataStatus = "processing";
      })
      .addCase(companyAccountDataAsync.fulfilled, (state, action) => {
        state.companyAccountData = action.payload;
        state.companyCardsData = action.payload;
        state.companyAccountDataStatus = "success";
      })
      .addCase(companyAccountDataAsync.rejected, (state) => {
        state.companyAccountDataStatus = "failed";
      })
      .addCase(bankDetailsExportAsync.pending, (state) => {
        state.bankDetailsExportStatus = "processing";
      })
      .addCase(bankDetailsExportAsync.fulfilled, (state, action) => {
        state.bankDetailsExportLink = action.payload;
        state.bankDetailsExportStatus = "success";
      })
      .addCase(bankDetailsExportAsync.rejected, (state) => {
        state.bankDetailsExportStatus = "failed";
      })
      .addCase(bankDetailsDownloadAsync.pending, (state) => {
        state.bankDetailsDownloadStatus = "processing";
      })
      .addCase(bankDetailsDownloadAsync.fulfilled, (state) => {
        state.bankDetailsExportLink.presignedUrl = null;
        state.bankDetailsExportLink.fileName = null;
        state.bankDetailsDownloadStatus = "success";
        state.bankDetailsExportStatus = "idle";
      })
      .addCase(bankDetailsDownloadAsync.rejected, (state) => {
        state.bankDetailsDownloadStatus = "failed";
      });
  },
});

export const selectCompanyAccountData = (state: RootState) =>
  state.accountData.companyAccountData;
export const selectCompanyCardsData = (state: RootState) =>
  state.accountData.companyCardsData;
export const selectCompanyDataStatus = (state: RootState) =>
  state.accountData.companyAccountDataStatus;
export const selectBankDetailsExportStatus = (state: RootState) =>
  state.accountData.bankDetailsExportStatus;
export const selectBankDetailsExportLink = (state: RootState) =>
  state.accountData.bankDetailsExportLink;

export default accountDataSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QueryStatus, authAxios } from "../utils";
import { RootState } from "../redux/store";
import { CreateMerchantCode, CreateMerchantGroup } from "./authorizationApi";
import { MerchantGroups } from "./supportingDocumentsApi";

export interface AuthorizationState {
  createMerchantGroupStatus: QueryStatus;
  createMerchantCodeStatus: QueryStatus;
  merchantGroupCreated: MerchantGroups | null;
}

const initialState: AuthorizationState = {
  createMerchantGroupStatus: "idle",
  createMerchantCodeStatus: "idle",
  merchantGroupCreated: null,
};

export const createMerchantGroupAsync = createAsyncThunk(
  "merchantGroup/create",
  async (payload: CreateMerchantGroup) => {
    const axios = authAxios();
    await axios.post(`/merchant_group`, payload);
    return payload.merchantGroup;
  },
);

export const createMerchantCodeAsync = createAsyncThunk(
  "merchantCode/create",
  async (payload: CreateMerchantCode) => {
    const axios = authAxios();
    await axios.post(`/merchant_code`, payload);
  },
);

export const authorizationSlice = createSlice({
  name: "authorization",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetCreateMerchantGroupStatus(state) {
      state.createMerchantGroupStatus = "idle";
    },
    resetCreateMerchantCodeStatus(state) {
      state.createMerchantCodeStatus = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(createMerchantGroupAsync.pending, (state) => {
        state.createMerchantGroupStatus = "processing";
      })
      .addCase(createMerchantGroupAsync.fulfilled, (state, action) => {
        state.createMerchantGroupStatus = "success";
        state.merchantGroupCreated = action.payload;
      })
      .addCase(createMerchantGroupAsync.rejected, (state) => {
        state.createMerchantGroupStatus = "failed";
      })
      .addCase(createMerchantCodeAsync.pending, (state) => {
        state.createMerchantCodeStatus = "processing";
      })
      .addCase(createMerchantCodeAsync.fulfilled, (state) => {
        state.createMerchantCodeStatus = "success";
      })
      .addCase(createMerchantCodeAsync.rejected, (state) => {
        state.createMerchantCodeStatus = "failed";
      });
  },
});

export const selectCreateMerchantGroupStatus = (state: RootState) =>
  state.authorization.createMerchantGroupStatus;
export const selectMerchantGroupCreated = (state: RootState) =>
  state.authorization.merchantGroupCreated;
export const selectCreateMerchantCodeStatus = (state: RootState) =>
  state.authorization.createMerchantCodeStatus;

export default authorizationSlice.reducer;

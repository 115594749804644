import { Spacer, ToastContainer, PageDS, TabMenu } from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BankStatementTab } from "./bankStatementTab";
import { StatementTab } from "./statementTab";
import { BankStatementCreationTab } from "./bankStatementCreation";
import { StatementCreationTab } from "./statementCreation";

export const Statements = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();
  const [selectedTab, setSelectedTab] = useState<string>(
    params.tab ?? "statementCreation",
  );

  const tabs = [
    {
      name: "statementCreation",
      title: t("statements.tab.statementCreation"),
    },
    {
      name: "statements",
      title: t("statements.tab.statements"),
    },
    {
      name: "bankStatementCreation",
      title: t("statements.tab.bankStatementCreation"),
    },
    {
      name: "bankStatements",
      title: t("statements.tab.bankStatements"),
    },
  ];

  useEffect(() => {
    if (selectedTab) navigate(`/home/statements/${selectedTab}/${params.id}`);
  }, [navigate, selectedTab, params.id]);

  return (
    <PageDS
      title={t("statements.title")}
      toaster={<ToastContainer />}
      topElement={
        <>
          <Spacer y={1} />
          <TabMenu
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </>
      }
    >
      <Spacer y={1} />
      <BankStatementTab />
      <StatementTab />
      <BankStatementCreationTab />
      <StatementCreationTab />
    </PageDS>
  );
};

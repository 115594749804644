import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QueryStatus, authAxios } from "../../utils";
import { RootState } from "../../redux/store";
import { CompanyFollowUp, CreateCompanyAcronymPayload } from "./followUpApi";

export interface AuthorizationsState {
  companyFollowUpStatus: QueryStatus;
  createCompanyAcronymStatus: QueryStatus;
  companyFollowUp: CompanyFollowUp[];
}

const initialState: AuthorizationsState = {
  companyFollowUpStatus: "idle",
  createCompanyAcronymStatus: "idle",
  companyFollowUp: [],
};

export const companyFollowUpAsync = createAsyncThunk(
  "companyFollowUp/call",
  async () => {
    const axios = authAxios();
    const response = await axios.get<CompanyFollowUp[]>(`/companies/follow_up`);

    return response.data.sort((a, b) => (a.name < b.name ? -1 : 1));
  },
);

export const createCompanyAcronymAsync = createAsyncThunk(
  "updateCompanyAcronym/call",
  async (payload: CreateCompanyAcronymPayload) => {
    const axios = authAxios();
    await axios.post(`/company/acronym`, payload);
  },
);

export const companyFollowUpSlice = createSlice({
  name: "companyFollowUp",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetUpdateStatus: (state) => {
      state.companyFollowUpStatus = "idle";
      state.createCompanyAcronymStatus = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(companyFollowUpAsync.pending, (state) => {
        state.companyFollowUpStatus = "processing";
      })
      .addCase(companyFollowUpAsync.fulfilled, (state, action) => {
        state.companyFollowUpStatus = "success";
        state.companyFollowUp = action.payload;
      })
      .addCase(companyFollowUpAsync.rejected, (state) => {
        state.companyFollowUpStatus = "failed";
      })
      .addCase(createCompanyAcronymAsync.pending, (state) => {
        state.createCompanyAcronymStatus = "processing";
      })
      .addCase(createCompanyAcronymAsync.fulfilled, (state) => {
        state.createCompanyAcronymStatus = "success";
      })
      .addCase(createCompanyAcronymAsync.rejected, (state) => {
        state.createCompanyAcronymStatus = "failed";
      });
  },
});

export const selectCompanyFollowUp = (state: RootState) =>
  state.companyFollowUp.companyFollowUp;
export const selectCompanyFollowUpStatus = (state: RootState) =>
  state.companyFollowUp.companyFollowUpStatus;
export const selectCreateCompanyAcronymStatus = (state: RootState) =>
  state.companyFollowUp.createCompanyAcronymStatus;

export default companyFollowUpSlice.reducer;

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Spacer, PageDS, ToastContainer, TabMenu } from "@qivia/ui";
import { useNavigate, useParams } from "react-router-dom";
import { DepositTab } from "./deposit";
import { InvoiceTab } from "./invoice";
import { PricingTab } from "./pricing";
import { AsfInvoiceTab } from "./asfInvoice";

export const Billings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [selectedTab, setSelectedTab] = useState<string>(
    params.tab ?? "deposits",
  );

  useEffect(() => {
    if (selectedTab) {
      navigate(
        `/home/billings/${selectedTab}${
          params.id !== undefined ? `/${params.id}` : ""
        }`,
      );
    }
  }, [navigate, params.id, selectedTab]);

  const tabs = [
    {
      name: "deposits",
      title: t("billings.tab.deposits"),
    },
    {
      name: "pricings",
      title: t("billings.tab.pricings"),
    },
    {
      name: "invoices",
      title: t("billings.tab.invoices"),
    },
    {
      name: "asfInvoices",
      title: t("billings.tab.asfInvoices"),
    },
  ];

  return (
    <PageDS
      title={t(`billings.${params.tab}`)}
      toaster={<ToastContainer />}
      topElement={
        <>
          <Spacer y={1} />
          <TabMenu
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </>
      }
    >
      <DepositTab />
      <InvoiceTab />
      <AsfInvoiceTab />
      <PricingTab />
    </PageDS>
  );
};

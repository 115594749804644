import {
  ButtonDS,
  NumericInputDS,
  SelectDS,
  Spacer,
  TableDS,
  TextCapitalized,
  dateFormatterDayLongMonthYearHourMinuteSecond,
  triggerToast,
  unreachable,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import styled from "styled-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  createPricingAsync,
  getPricingsListAsync,
  pricingSlice,
  selectCreatePricingStatus,
  selectPricingsList,
} from "./pricingSlice";
import { PricingsList } from "./pricingApi";
import {
  companiesListAsync,
  selectCompaniesList,
  selectCompaniesListStatus,
} from "./../companiesSlice";
import { useNavigate, useParams } from "react-router-dom";

export const PricingTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [pricePerCard, setPricePerCard] = useState<number | "">("");
  const [percentagePerCardTransaction, setPercentagePerCardTransaction] =
    useState<number | "">("");
  const [pricePerBadge, setPricePerBadge] = useState<number | "">("");
  const [percentagePerBadgeTransaction, setPercentagePerBadgeTransaction] =
    useState<number | "">("");
  const [pricePerAnalyticVehicles, setPricePerAnalyticVehicle] = useState<
    number | ""
  >("");
  const [pricePerAdvancedAnalyticVehicles, setPricePerAdvancedAnalyticVehicle] =
    useState<number | "">("");
  const companyList = useAppSelector(selectCompaniesList);
  const companiesListStatus = useAppSelector(selectCompaniesListStatus);
  const createPricingStatus = useAppSelector(selectCreatePricingStatus);
  const pricingsList = useAppSelector(selectPricingsList);
  const [companySelected, setCompanySelected] = useState<string>("");
  const [displayError, setDisplayError] = useState<boolean>(false);

  const isValidSubmission = useMemo(
    () =>
      companySelected !== "" &&
      pricePerCard !== "" &&
      percentagePerCardTransaction !== "" &&
      pricePerBadge !== "" &&
      percentagePerBadgeTransaction !== "" &&
      pricePerAnalyticVehicles !== "" &&
      pricePerAdvancedAnalyticVehicles !== "",
    [
      companySelected,
      percentagePerBadgeTransaction,
      percentagePerCardTransaction,
      pricePerAdvancedAnalyticVehicles,
      pricePerAnalyticVehicles,
      pricePerBadge,
      pricePerCard,
    ],
  );

  const resetValue = useCallback(() => {
    setPricePerCard("");
    setPricePerBadge("");
    setPercentagePerCardTransaction("");
    setPercentagePerBadgeTransaction("");
    setPricePerAnalyticVehicle("");
    setPricePerAdvancedAnalyticVehicle("");
  }, []);

  const optionCompany = useMemo(
    () =>
      companyList.map((company) => {
        return {
          value: company.uuid,
          label: company.name,
        };
      }),
    [companyList],
  );

  const valueOptionCompany = useMemo(
    () => optionCompany.find((option) => option.value === companySelected),
    [optionCompany, companySelected],
  );

  useEffect(() => {
    if (companiesListStatus === "idle") {
      void dispatch(companiesListAsync());
    }
  }, [dispatch, companiesListStatus]);

  useEffect(() => {
    if (params.id && !companySelected) {
      setCompanySelected(params.id);
    }
  }, [companySelected, params.id]);

  useEffect(() => {
    if (companySelected) {
      void dispatch(getPricingsListAsync({ company: companySelected }));
      navigate(`/home/billings/${params.tab}/${companySelected}`);
    }
  }, [companySelected, dispatch, createPricingStatus, navigate, params.tab]);

  useEffect(() => {
    if (createPricingStatus === "success") {
      triggerToast(t("pricing.creation.success") || "", "valid");
      dispatch(pricingSlice.actions.resetCreatePricingStatus());
    } else if (createPricingStatus === "failed") {
      triggerToast(t("pricing.creation.failure") || "", "error");
    }
  }, [createPricingStatus, t, dispatch]);

  const clickToSubmit = useCallback(() => {
    if (!isValidSubmission) {
      setDisplayError(true);
    } else {
      setDisplayError(false);
      void dispatch(
        createPricingAsync({
          company: companySelected,
          pricePerCard: pricePerCard === "" ? 0 : pricePerCard,
          percentagePerCardTransaction:
            percentagePerCardTransaction === ""
              ? 0
              : percentagePerCardTransaction,
          pricePerBadge: pricePerBadge === "" ? 0 : pricePerBadge,
          pricePerAnalyticVehicles:
            pricePerAnalyticVehicles === "" ? 0 : pricePerAnalyticVehicles,
          pricePerAdvancedAnalyticVehicles:
            pricePerAdvancedAnalyticVehicles === ""
              ? 0
              : pricePerAdvancedAnalyticVehicles,
          percentagePerBadgeTransaction:
            percentagePerBadgeTransaction === ""
              ? 0
              : percentagePerBadgeTransaction,
        }),
      );
      resetValue();
    }
  }, [
    companySelected,
    dispatch,
    isValidSubmission,
    percentagePerBadgeTransaction,
    percentagePerCardTransaction,
    pricePerAdvancedAnalyticVehicles,
    pricePerAnalyticVehicles,
    pricePerBadge,
    pricePerCard,
    resetValue,
  ]);

  const headers = {
    company: {
      text: t("pricing.column.company"),
    },
    pricePerCard: {
      text: t("pricing.column.pricePerCard"),
    },
    percentagePerCardTransaction: {
      text: t("pricing.column.percentagePerCardTransaction"),
    },
    pricePerBadge: {
      text: t("pricing.column.pricePerBadge"),
    },
    percentagePerBadgeTransaction: {
      text: t("pricing.column.percentagePerBadgeTransaction"),
    },
    pricePerAnalyticVehicles: {
      text: t("pricing.column.pricePerAnalyticVehicles"),
    },
    pricePerAdvancedAnalyticVehicles: {
      text: t("pricing.column.pricePerAdvancedAnalyticVehicles"),
    },
    createdAt: {
      text: t("pricing.column.createdAt"),
    },
    creator: {
      text: t("pricing.column.creator"),
    },
  };

  if (params.tab !== "pricings") {
    return;
  }

  const render = (row: PricingsList) => (key: keyof PricingsList) => {
    switch (key) {
      case "company":
        return valueOptionCompany?.label;
      case "createdAt":
        return dateFormatterDayLongMonthYearHourMinuteSecond(
          new Date(row[key]),
        );
      case "pricePerCard":
      case "pricePerBadge":
      case "pricePerAnalyticVehicles":
      case "pricePerAdvancedAnalyticVehicles":
        return row[key] + " €";

      case "percentagePerCardTransaction":
      case "percentagePerBadgeTransaction":
        return row[key] + " %";

      case "creator":
        return row[key];
    }
    unreachable(key);
  };

  return (
    <StyledContainer>
      <Spacer y={1} />
      <StyledHeader>
        <TextCapitalized>{t("pricing.select.company")}</TextCapitalized>
        <SelectDS
          label={""}
          value={valueOptionCompany}
          options={optionCompany}
          onChange={(selectedOption) =>
            setCompanySelected(selectedOption ? selectedOption.value : "")
          }
          error={
            (displayError &&
              companySelected === "" &&
              t("pricing.select.company.error")) ||
            undefined
          }
          allWidth
        />
      </StyledHeader>
      <StyledFlex>
        <StyledColumn>
          <TextCapitalized>{t("pricing.input.pricePerCard")}</TextCapitalized>
          <NumericInputDS
            value={pricePerCard}
            update={(value) =>
              setPricePerCard(value !== "" && value > 100 ? 100 : value)
            }
            floatNumber
            error={
              (displayError &&
                pricePerCard === "" &&
                t("pricing.emptyInput.error")) ||
              undefined
            }
          />
          <TextCapitalized>
            {t("pricing.input.percentagePerCardTransaction")}
          </TextCapitalized>
          <NumericInputDS
            value={percentagePerCardTransaction}
            update={(value) =>
              setPercentagePerCardTransaction(
                value !== "" && value > 100 ? 100 : value,
              )
            }
            floatNumber
            error={
              (displayError &&
                percentagePerCardTransaction === "" &&
                t("pricing.emptyInput.error")) ||
              undefined
            }
          />
        </StyledColumn>
        <StyledColumn>
          <TextCapitalized>{t("pricing.input.pricePerBadge")}</TextCapitalized>
          <NumericInputDS
            value={pricePerBadge}
            update={(value) =>
              setPricePerBadge(value !== "" && value > 100 ? 100 : value)
            }
            floatNumber
            error={
              (displayError &&
                pricePerBadge === "" &&
                t("pricing.emptyInput.error")) ||
              undefined
            }
          />
          <TextCapitalized>
            {t("pricing.input.percentagePerBadgeTransaction")}
          </TextCapitalized>
          <NumericInputDS
            value={percentagePerBadgeTransaction}
            update={(value) =>
              setPercentagePerBadgeTransaction(
                value !== "" && value > 100 ? 100 : value,
              )
            }
            floatNumber
            error={
              (displayError &&
                percentagePerBadgeTransaction === "" &&
                t("pricing.emptyInput.error")) ||
              undefined
            }
          />
        </StyledColumn>
        <StyledColumn>
          <TextCapitalized>
            {t("pricing.input.pricePerAnalyticVehicles")}
          </TextCapitalized>
          <NumericInputDS
            value={pricePerAnalyticVehicles}
            update={(value) =>
              setPricePerAnalyticVehicle(
                value !== "" && value > 100 ? 100 : value,
              )
            }
            floatNumber
            error={
              (displayError &&
                pricePerAnalyticVehicles === "" &&
                t("pricing.emptyInput.error")) ||
              undefined
            }
          />
          <TextCapitalized>
            {t("pricing.input.pricePerAdvancedAnalyticVehicles")}
          </TextCapitalized>
          <NumericInputDS
            value={pricePerAdvancedAnalyticVehicles}
            update={(value) =>
              setPricePerAdvancedAnalyticVehicle(
                value !== "" && value > 100 ? 100 : value,
              )
            }
            floatNumber
            error={
              (displayError &&
                pricePerAdvancedAnalyticVehicles === "" &&
                t("pricing.emptyInput.error")) ||
              undefined
            }
          />
        </StyledColumn>
      </StyledFlex>
      <StyledFlex>
        <ButtonDS
          format="fill"
          buttonType="primary"
          text={t("pricing.button.create")}
          onClick={clickToSubmit}
          disabled={!isValidSubmission}
        />
      </StyledFlex>
      <Spacer y={2} />
      <StyledTable>
        <TableDS<keyof PricingsList, PricingsList>
          data={pricingsList}
          headers={headers}
          render={render}
        />
      </StyledTable>
      <Spacer y={3} />
    </StyledContainer>
  );
};

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
`;
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const StyledFlex = styled.div`
  display: flex;
`;
const StyledColumn = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
`;

const StyledTable = styled.div`
  height: 100%;
  overflow: auto;
`;

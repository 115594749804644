import { ButtonDS, SelectDS, TextCapitalized, triggerToast } from "@qivia/ui";
import { Spacer } from "@qivia/ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { companiesListAsync } from "../companiesSlice";
import { selectCompaniesListStatus } from "../companiesSlice";
import { selectCompaniesList } from "../companiesSlice";
import {
  createStatementForPeriodAsync,
  selectStatementForPeriodCreationStatus,
  statementsSlice,
} from "./statementSlice";

export const StatementCreationTab = () => {
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const companiesList = useAppSelector(selectCompaniesList);
  const companiesListStatus = useAppSelector(selectCompaniesListStatus);
  const createStatementForPeriodStatus = useAppSelector(
    selectStatementForPeriodCreationStatus,
  );
  const [displayError, setDisplayError] = useState<boolean>(false);

  const [companySelected, setCompanySelected] = useState<string>("");
  const [beginningDateSelected, setBeginningDateSelected] = useState("");
  const [endDateSelected, setEndDateSelected] = useState("");

  const isValidSubmission = useMemo(
    () =>
      companySelected !== "" &&
      beginningDateSelected !== "" &&
      endDateSelected !== "",
    [companySelected, beginningDateSelected, endDateSelected],
  );

  const clickToSubmit = useCallback(() => {
    if (!isValidSubmission) {
      setDisplayError(true);
    } else {
      setDisplayError(false);
      setCompanySelected("");
      setBeginningDateSelected("");
      setEndDateSelected("");
      void dispatch(
        statementsSlice.actions.resetCreateStatementForPeriodStatus(),
      );
      void dispatch(
        createStatementForPeriodAsync({
          companyUuid: companySelected,
          from: new Date(beginningDateSelected).toISOString().split("T")[0],
          to: new Date(endDateSelected).toISOString().split("T")[0],
        }),
      );
    }
  }, [
    beginningDateSelected,
    companySelected,
    dispatch,
    endDateSelected,
    isValidSubmission,
  ]);

  const optionCompany = useMemo(
    () =>
      companiesList.map((company) => {
        return {
          value: company.uuid,
          label: company.name,
        };
      }),
    [companiesList],
  );

  const valueOptionCompany = useMemo(
    () => optionCompany.find((option) => option.value === companySelected),
    [optionCompany, companySelected],
  );

  useEffect(() => {
    if (companiesListStatus === "idle") {
      void dispatch(companiesListAsync());
    }
  }, [dispatch, companiesListStatus]);

  useEffect(() => {
    if (createStatementForPeriodStatus === "success") {
      triggerToast(t("statementForPeriod.creation.success") || "", "valid");
    } else if (createStatementForPeriodStatus === "failed") {
      triggerToast(t("statementForPeriod.creation.failure") || "", "error");
    }
  }, [createStatementForPeriodStatus, t, dispatch]);

  if (params.tab !== "statementCreation") {
    return;
  }

  return (
    <StyledContainer>
      <TextCapitalized>
        {t("statementForPeriod.select.company")}
      </TextCapitalized>
      <SelectDS
        label={""}
        value={valueOptionCompany}
        options={optionCompany}
        onChange={(selectedOption) =>
          setCompanySelected(selectedOption ? selectedOption.value : "")
        }
        error={
          (displayError &&
            companySelected === "" &&
            t("asfInvoice.select.company.error")) ||
          undefined
        }
        allWidth
      />
      <TextCapitalized>
        {t("statementForPeriod.select.beginningDate")}
      </TextCapitalized>
      <input
        type="date"
        min="2023-01-01"
        value={beginningDateSelected}
        onChange={(e) => {
          setBeginningDateSelected(e.target.value);
          if (e.target.value > endDateSelected) {
            setEndDateSelected("");
          }
        }}
      />
      <Spacer y={1} />
      <StyledWarningLabel>
        <TextCapitalized>
          {t("statementForPeriod.select.endDate")}
        </TextCapitalized>
        <Spacer y={0.5} />
        <StyledWarningTextColor>
          ⚠️
          <TextCapitalized>
            {t("statementForPeriod.select.endDate.warning")}
          </TextCapitalized>
        </StyledWarningTextColor>
      </StyledWarningLabel>
      <Spacer y={0.5} />
      <input
        type="date"
        min={new Date(beginningDateSelected).setDate(
          new Date(beginningDateSelected).getDate() - 1,
        )}
        value={endDateSelected}
        onChange={(e) => setEndDateSelected(e.target.value)}
      />
      <Spacer y={1} />
      <ButtonDS
        format="hug"
        buttonType="primary"
        text={t("statementForPeriod.button.create")}
        onClick={clickToSubmit}
        disabled={!isValidSubmission}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledWarningLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledWarningTextColor = styled.div`
  color: red;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

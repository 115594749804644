import {
  ButtonDS,
  EditOutlined,
  LaunchPage,
  MaterialUiColumnsProps,
  MaterialUiTable,
  SelectDS,
  Spacer,
  Tag,
  TextCapitalized,
  dateFormatterDayMonthYearLong2Digits,
  triggerToast,
  usePersistMuiTableState,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import styled from "styled-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  companiesListAsync,
  companiesSlice,
  selectCompaniesList,
  selectCompaniesListStatus,
} from "../companiesSlice";
import { getCategoryName } from "../../lib/utils";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import {
  rejectedAuthorizationsAsync,
  rejectedAuthorizationsSlice,
  selectRejectedAuthorizationsList,
  selectRejectedAuthorizationsListStatus,
} from "../companiesData/rejectedAuthorizationsSlice";
import { RejectedAuthorizationsList } from "../companiesData/rejectedAuthorizationsApi";
import { AuthorizationsWithoutMerchantGroupListDisplayed } from "./merchantFixApi";
import { formatDate, getDate } from "../../lib/materialUiFormats";
import { AuthorizationModal } from "../modals/authorizationModal";
import {
  authorizationSlice,
  selectCreateMerchantGroupStatus,
} from "../authorizationSlice";

export const MerchantGroupTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const companiesList = useAppSelector(selectCompaniesList);
  const companiesListStatus = useAppSelector(selectCompaniesListStatus);
  const authorizationsWithoutMerchantGroupList = useAppSelector(
    selectRejectedAuthorizationsList,
  );
  const authorizationsWithoutMerchantGroupStatus = useAppSelector(
    selectRejectedAuthorizationsListStatus,
  );
  const createMerchantGroupStatus = useAppSelector(
    selectCreateMerchantGroupStatus,
  );

  const [companySelected, setCompanySelected] = useState<string>("");
  const [listDisplayed, setListDisplayed] = useState<
    AuthorizationsWithoutMerchantGroupListDisplayed[] | null
  >(null);
  const [currentAuthorizationDetailsId, setCurrentAuthorizationDetailsUuid] =
    useState<string | null>(null);

  const pageName = "merchantGroup";
  const apiRef = useGridApiRef();
  const key = "customers-grid";

  const aggregation = {
    model: {
      amount: "avg",
      merchantName: "size",
      category: "size",
      driverName: "size",
    },
  };

  const dataGridState = usePersistMuiTableState(
    apiRef,
    key,
    pageName,
    aggregation,
  );

  const resetValue = useCallback(() => {
    setCurrentAuthorizationDetailsUuid(null);
    dispatch(companiesSlice.actions.resetCompaniesListStatus());
    dispatch(authorizationSlice.actions.resetCreateMerchantGroupStatus());
    dispatch(
      rejectedAuthorizationsSlice.actions.resetAuthorizationsListStatus(),
    );
    setCompanySelected("");
  }, [dispatch]);

  useEffect(() => {
    if (createMerchantGroupStatus === "success") {
      triggerToast(
        t("authorization.createMerchantGroup.success") || "",
        "valid",
      );
      resetValue();
    } else if (createMerchantGroupStatus === "failed") {
      triggerToast(
        t("authorization.createMerchantGroup.failure") || "",
        "error",
      );
    }
  }, [createMerchantGroupStatus, resetValue, t]);

  const findKeysToTranslate = useCallback(
    (key: keyof RejectedAuthorizationsList, value: string | number) => {
      switch (key) {
        case "category":
          return t(`category.${value}`);
        case "executionDate":
          return dateFormatterDayMonthYearLong2Digits(new Date(value));
        default:
          return value;
      }
    },
    [t],
  );

  const keysToTranslate = useMemo(
    () =>
      [
        "category",
        "executionDate",
      ] as (keyof AuthorizationsWithoutMerchantGroupListDisplayed)[],
    [],
  );

  const optionCompany = useMemo(
    () =>
      companiesList.map((company) => {
        return {
          label: company.name,
          value: company.uuid,
        };
      }),
    [companiesList],
  );

  const valueOptionCompany = useMemo(
    () => optionCompany.find((option) => option.value === companySelected),
    [companySelected, optionCompany],
  );

  const allAuthorizationsWithoutMerchantGroupListDisplayed: RejectedAuthorizationsList[] =
    useMemo(() => {
      if (!authorizationsWithoutMerchantGroupList) return [];

      const authorizationsFiltered =
        authorizationsWithoutMerchantGroupList.filter(
          (a) =>
            a.merchantGroup === null &&
            (a.category === "FUEL_AUTOMATON" ||
              a.category === "SERVICE_STATION") &&
            !a.merchantGroupAlreadyCorrected,
        );

      const rowsWithId = authorizationsFiltered.map((av) => {
        let newDict: RejectedAuthorizationsList & {
          id: string;
        } = {
          ...av,
          id: av.uuid,
        };
        Object.entries(av).forEach(([key, value]) => {
          if (
            keysToTranslate.includes(
              key as keyof AuthorizationsWithoutMerchantGroupListDisplayed,
            )
          ) {
            newDict = {
              ...newDict,
              [key]: value
                ? findKeysToTranslate(
                    key as keyof RejectedAuthorizationsList,
                    value as string,
                  )
                : ("" as string),
            };
          }
        });
        return newDict;
      });
      return rowsWithId;
    }, [
      authorizationsWithoutMerchantGroupList,
      findKeysToTranslate,
      keysToTranslate,
    ]);

  const currentAuthorizationDetails: RejectedAuthorizationsList | null =
    useMemo(
      () =>
        allAuthorizationsWithoutMerchantGroupListDisplayed.find(
          (t) => "id" in t && t.id === currentAuthorizationDetailsId,
        ) ?? null,
      [
        allAuthorizationsWithoutMerchantGroupListDisplayed,
        currentAuthorizationDetailsId,
      ],
    );

  const searchBarProps = {
    values: allAuthorizationsWithoutMerchantGroupListDisplayed,
    setFilterValues: setListDisplayed,
    keysToIgnore: [
      "uuid" as const,
      "merchantCode" as const,
      "cardLast4Digits" as const,
      "reasonsRefused" as const,
      "driver" as const,
      "driverFirstName" as const,
      "driverLastName" as const,
      "ruleUuid" as const,
      "driver" as const,
      "vehicleUuid" as const,
      "cardUuid" as const,
      "vehicleReference" as const,
      "merchantGroup" as const,
    ],
  };

  useEffect(() => {
    if (companiesListStatus === "idle") {
      void dispatch(companiesListAsync());
    }
  }, [companiesListStatus, dispatch]);

  useEffect(() => {
    if (
      companySelected &&
      authorizationsWithoutMerchantGroupStatus === "idle"
    ) {
      void dispatch(
        rejectedAuthorizationsAsync({
          companyUuid: companySelected,
        }),
      );
    }
  }, [authorizationsWithoutMerchantGroupStatus, companySelected, dispatch]);

  useEffect(() => {
    if (
      allAuthorizationsWithoutMerchantGroupListDisplayed &&
      companiesList &&
      companiesListStatus === "success"
    ) {
      setListDisplayed(allAuthorizationsWithoutMerchantGroupListDisplayed);
    }
  }, [
    allAuthorizationsWithoutMerchantGroupListDisplayed,
    authorizationsWithoutMerchantGroupStatus,
    companiesList,
    companiesListStatus,
  ]);

  const columnsDisplayed: {
    [index in keyof AuthorizationsWithoutMerchantGroupListDisplayed]: MaterialUiColumnsProps;
  } = useMemo(() => {
    return {
      merchantGroupCreation: {
        type: "string",
        width: 250,
        valueFormatter: (value, row) => {
          return (
            <StyledButton>
              <Spacer y={0.5} />
              <ButtonDS
                singleIcon={{
                  icon: <EditOutlined />,
                  size: "L",
                }}
                format={"fill"}
                buttonType={"secondary"}
                onClick={() => {
                  setCurrentAuthorizationDetailsUuid(row["id"] as string);
                }}
              />
              <Spacer y={0.5} />
            </StyledButton>
          );
        },
      },
      executionDate: {
        type: "date",
        width: 140,
        valueGetter: getDate,
        valueFormatter: formatDate,
      },
      driverName: {
        type: "string",
        width: 250,
        valueFormatter: (value: string) => {
          if (!value) return <>-</>;
          return value && <StyledRow>{value}</StyledRow>;
        },
      },
      merchantName: {
        type: "string",
        width: 200,
      },
      merchantCity: {
        type: "string",
        width: 200,
      },
      category: {
        type: "string",
        valueFormatter: (value: string) => {
          const merchantCategory = getCategoryName(value);
          return (
            value && (
              <StyledRow>
                <Tag
                  text={merchantCategory}
                  backgroundColor={
                    colors["colors/surfaces/background/background_level2"]
                  }
                  textColor={colors["colors/text/black"]}
                  colorHover={
                    colors["colors/surfaces/background/background_level0"]
                  }
                />
              </StyledRow>
            )
          );
        },
      },
      registrationNumber: {
        type: "string",
        width: 200,
        valueFormatter: (value: string) => {
          if (!value) return <>-</>;
          return value && <>{value}</>;
        },
      },
      ruleName: {
        type: "string",
        width: 200,
      },
      amount: {
        type: "number",
        width: 200,
        valueFormatter: (value: string) => {
          return value && <>{value} €</>;
        },
      },
    };
  }, []);

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  if (params.tab !== "merchantGroup") {
    return;
  }

  return (
    <StyledContainer>
      <Spacer y={1} />
      <StyledHeader>
        <TextCapitalized>{t("merchantGroup.select.company")}</TextCapitalized>
        <SelectDS
          label={""}
          value={valueOptionCompany}
          options={optionCompany}
          onChange={(selectedOption) =>
            setCompanySelected(selectedOption ? selectedOption.value : "")
          }
          allWidth
        />
      </StyledHeader>
      <Spacer y={1} />
      <MaterialUiTable<
        keyof AuthorizationsWithoutMerchantGroupListDisplayed,
        AuthorizationsWithoutMerchantGroupListDisplayed
      >
        rows={listDisplayed}
        columns={columnsDisplayed}
        pageName={pageName}
        dataGridState={dataGridState}
        searchBar={searchBarProps}
        apiRef={apiRef}
      />
      {currentAuthorizationDetails && (
        <>
          <Spacer y={2} />
          <AuthorizationModal
            visible={!!currentAuthorizationDetails}
            onCloseModal={() => setCurrentAuthorizationDetailsUuid(null)}
            merchantName={currentAuthorizationDetails.merchantName}
            authorizationId={currentAuthorizationDetails.uuid}
          />
        </>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
  z-index: 10;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StyledButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QueryStatus, authAxios } from "../../../src/utils";
import { RootState } from "../../redux/store";
import { CreatePricing, PricingsList } from "./pricingApi";

export interface PricingState {
  createPricingStatus: QueryStatus;
  pricingsList: PricingsList[];
  pricingsListStatus: QueryStatus;
}

const initialState: PricingState = {
  createPricingStatus: "idle",
  pricingsList: [],
  pricingsListStatus: "idle",
};

export const createPricingAsync = createAsyncThunk(
  "createPricingAsync/call",
  async (payload: CreatePricing) => {
    const axios = authAxios();
    await axios.post(`pricing_creation`, payload);
  },
);

export const getPricingsListAsync = createAsyncThunk(
  "getPricingsListAsync/call",
  async (payload: { company: string }) => {
    const axios = authAxios();
    const response = await axios.get<PricingsList[]>(
      `/company/${payload.company}/pricings`,
    );
    const formattedResult = response.data.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );
    return formattedResult;
  },
);

export const pricingSlice = createSlice({
  name: "pricing",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetCreatePricingStatus(state) {
      state.createPricingStatus = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(createPricingAsync.pending, (state) => {
        state.createPricingStatus = "processing";
      })
      .addCase(createPricingAsync.fulfilled, (state) => {
        state.createPricingStatus = "success";
        state.pricingsListStatus = "idle";
      })
      .addCase(createPricingAsync.rejected, (state) => {
        state.createPricingStatus = "failed";
      })
      .addCase(getPricingsListAsync.pending, (state) => {
        state.pricingsListStatus = "processing";
      })
      .addCase(getPricingsListAsync.fulfilled, (state, action) => {
        state.pricingsList = action.payload;
        state.pricingsListStatus = "success";
      })
      .addCase(getPricingsListAsync.rejected, (state) => {
        state.pricingsListStatus = "failed";
      });
  },
});

export const selectCreatePricingStatus = (state: RootState) =>
  state.pricing.createPricingStatus;
export const selectPricingsList = (state: RootState) =>
  state.pricing.pricingsList;
export const selectPricingsListStatus = (state: RootState) =>
  state.pricing.pricingsListStatus;

export default pricingSlice.reducer;
